<script>
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import FieldVisitServices from "../../services/FieldVisitServices";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Liste des visites",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Liste des visites",
      items: [
        {
          text: "Visites",
          href: "/",
        },
        {
          text: "Liste des Visites",
          active: true,
        },
      ],
      fieldVisits: [],
      currentVisit: null,
      fields: {
        field_id: 1,
        trip_mode: "car",
        amount: 0,
        sheduled_at: null,
      },
      isCreateModalOpen: false,
      isUpdateModalOpen: false,
    };
  },
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    MoreHorizontalIcon,
  },
  async beforeRouteEnter() {
    let visits = [];
    await FieldVisitServices.getAll()
      .then((data) => {
        visits = data.visits;
      })
      .catch((er) => {
        console.log(er);
      });

    localStorage.setItem("@FIELD_VISITS_LIST", JSON.stringify(visits));
  },
  beforeMount() {
    this.fieldVisits = JSON.parse(
      localStorage.getItem("@FIELD_VISITS_LIST") || "[]"
    );
  },
  methods: {
    async getFieldVisits() {
      await FieldVisitServices.getAll()
        .then((data) => {
          this.fieldVisits = data.visits;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    handleOpenCreate() {
      this.isCreateModalOpen = true;
    },
    handleOpenUpdate(visit) {
      this.isUpdateModalOpen = true;
      this.currentVisit = visit;
      this.fields.field_id = visit.field_id;
      this.fields.amount = visit.amount;
      this.fields.trip_mode = visit.trip_mode;
      this.fields.sheduled_at = visit.sheduled_at
        .split(".")[0]
        .replace("T", " ");
    },
    async handleDeleteFieldVisit(item) {
      await Swal.fire({
        title: "Êtes-vous sûr?",
        text: "Cette opération est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await FieldVisitServices.delete(item.id)
            .then(() => {
              this.fieldVisits = this.fieldVisits.filter(
                (visit) => visit.id !== item.id
              );
              Swal.fire(
                "Supprimé!",
                "La visite a bien été supprimée.",
                "success"
              );
            })
            .catch((er) => {
              console.log(er);
            });
        }
      });
    },
    async handleCreateFieldVisit() {
      FieldVisitServices.create(this.fields).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite créée avec succès",
            icon: "success",
          });

          this.fields = {
            field_id: null,
            trip_mode: null,
            amount: null,
            sheduled_at: null,
          };
        }

        this.getFieldVisits();
      });
    },
    async handleUpdateFieldVisit() {
      FieldVisitServices.update(this.currentVisit.id, {
        ...this.fields,
        sheduled_at: new Date(this.fields.sheduled_at).toISOString(),
      }).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite mise à jour avec succès",
            icon: "success",
          });
        }

        this.getFieldVisits();
      });
    },
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal
      v-model="isCreateModalOpen"
      hide-footer
      title="Ajout d'une visite"
      class="v-modal-custom"
      header-class="bg-light p-3"
    >
      <form action="#" id="addform">
        <div class="mb-3">
          <label for="status-field" class="form-label"> Choisir le site </label>
          <select
            class="form-control"
            data-trigger
            id="statusfield"
            v-model="fields.field_id"
          >
            <option value="1">Site de PK30</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="email-field" class="form-label"> Date et heure </label>
          <input
            type="datetime-local"
            data-bs-provider="flatpickr"
            data-bs-date-format="d.m.y"
            data-bs-enable-time
            id="date"
            class="form-control"
            placeholder="Entrer l'heure et la date"
            required
            v-model="fields.sheduled_at"
          />
        </div>

        <div class="mb-3">
          <label for="status-field" class="form-label">
            Mode de transport
          </label>
          <select
            class="form-control"
            data-trigger
            id="statusfield"
            v-model="fields.trip_mode"
          >
            <option value="car">Voiture</option>
            <option value="bus">Bus</option>
            <option value="bycicle">Moto</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="amount" class="form-label">Montant</label>
          <input
            type="tel"
            id="phone"
            class="form-control"
            placeholder="Entrer le coût de la visite"
            required
            v-model="fields.amount"
          />
        </div>
        <div class="modal-footer v-modal-footer">
          <div class="hstack gap-2 justify-content-end">
            <button
              type="button"
              id="closemodal"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="handleCreateFieldVisit"
            >
              Enregistrer la visite
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="isUpdateModalOpen"
      hide-footer
      title="Modifier une visite"
      class="v-modal-custom"
      header-class="bg-light p-3"
    >
      <form action="#" id="addform">
        <div class="mb-3">
          <label for="status-field" class="form-label"> Choisir le site </label>
          <select
            class="form-control"
            data-trigger
            id="statusfield"
            v-model="fields.field_id"
          >
            <option value="1">Site de PK30</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="email-field" class="form-label"> Date et heure </label>
          <input
            type="datetime-local"
            data-bs-provider="flatpickr"
            data-bs-date-format="d.m.y"
            data-bs-enable-time
            id="date"
            class="form-control"
            placeholder="Entrer l'heure et la date"
            required
            v-model="fields.sheduled_at"
          />
        </div>

        <div class="mb-3">
          <label for="status-field" class="form-label">
            Mode de transport
          </label>
          <select
            class="form-control"
            data-trigger
            id="statusfield"
            v-model="fields.trip_mode"
          >
            <option value="car">Voiture</option>
            <option value="bus">Bus</option>
            <option value="bycicle">Moto</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="amount" class="form-label">Montant</label>
          <input
            type="tel"
            id="phone"
            class="form-control"
            placeholder="Entrer le coût de la visite"
            required
            v-model="fields.amount"
          />
        </div>
        <div class="modal-footer v-modal-footer">
          <div class="hstack gap-2 justify-content-end">
            <button
              type="button"
              id="closemodal"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="handleUpdateFieldVisit"
            >
              Enregistrer la visite
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="row g-4 mb-3">
      <div class="col-sm-auto">
        <div>
          <button class="btn btn-success" @click="handleOpenCreate">
            <i class="ri-add-line align-bottom me-1"></i> Nouvelle visite
          </button>
        </div>
      </div>
      <div class="col-sm">
        <div class="d-flex justify-content-sm-end gap-2">
          <div class="search-box ms-2">
            <input
              type="text"
              class="form-control"
              placeholder="Rechercher..."
            />
            <i class="ri-search-line search-icon"></i>
          </div>

          <!-- <Multiselect
            class="multiselect form-control w-lg w-auto m-0"
            v-model="value"
            :close-on-select="true"
            :searchable="true"
            :create-option="true"
            :options="[
              { value: 'Status', label: 'Status' },
              { value: 'Active', label: 'Active' },
              { value: 'Block', label: 'Block' },
            ]"
          /> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-xxl-3 col-sm-6 project-card"
        v-for="(item, index) of fieldVisits.sort((item1, item2) => item2.id - item1.id)"
        :key="index"
      >
        <div class="card card-height-100">
          <div class="card-body">
            <div class="d-flex flex-column h-100">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <p class="text-muted mb-4">
                    Le {{ new Date(item.sheduled_at).toLocaleString() }}
                  </p>
                </div>
                <div class="flex-shrink-0">
                  <div class="d-flex gap-1 align-items-center">
                    <div class="dropdown">
                      <button
                        class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <MoreHorizontalIcon
                          class="icon-sm"
                        ></MoreHorizontalIcon>
                      </button>

                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'field-visits.details',
                            params: { id: item.id },
                          }"
                          ><i
                            class="ri-eye-fill align-bottom me-2 text-muted"
                          ></i>
                          Voir les détails
                        </router-link>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          @click="handleOpenUpdate(item)"
                        >
                          <i
                            class="ri-pencil-fill align-bottom me-2 text-muted"
                          ></i>
                          Modifier
                        </a>
                        <div class="dropdown-divider"></div>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          @click.prevent="handleDeleteFieldVisit(item)"
                        >
                          <i
                            class="ri-delete-bin-fill align-bottom me-2 text-muted"
                          ></i>
                          Supprimer
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-sm">
                    <span class="avatar-title bg-soft-info rounded p-2">
                      <img
                        :src="'/assets/icons/' + item.trip_mode + '.png'"
                        alt=""
                        class="img-fluid p-0"
                      />
                    </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h5 class="mb-1 fs-15">
                    <router-link
                      :to="{
                        name: 'field-visits.details',
                        params: { id: item.id },
                      }"
                      class="text-dark"
                    >
                      {{ item.field.name }}
                    </router-link>
                  </h5>
                  <p class="text-muted text-truncate-two-lines mb-3">
                    {{ $formatPrice(item.amount) }}
                  </p>
                </div>
              </div>
              <div class="mt-auto">
                <div class="d-flex mb-2">
                  <div class="flex-grow-1">
                    <div>Souscriptions</div>
                  </div>
                  <div class="flex-shrink-0">
                    <div>
                      <i class="ri-list-check align-bottom me-1 text-muted"></i>
                      {{ String(item.subscriptions.length).padStart(2, "0") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
          <div class="card-footer bg-transparent border-top-dashed py-2">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <div class="text-muted">
                  <i class="ri-calendar-event-fill me-1 align-bottom"></i>
                  Ajouté le {{ new Date(item.created_at).toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
          <!-- end card footer -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <!-- <div class="row g-0 text-center text-sm-start align-items-center mb-4">
      <div class="col-sm-6">
        <div>
          <p class="mb-sm-0 text-muted">
            Showing <span class="fw-semibold">1</span> to
            <span class="fw-semibold">10</span> of
            <span class="fw-semibold text-decoration-underline">12</span>
            entries
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <ul
          class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0"
        >
          <li class="page-item disabled">
            <a href="#" class="page-link">Previous</a>
          </li>
          <li class="page-item active">
            <a href="#" class="page-link">1</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">2</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">3</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">4</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">5</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">Next</a>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- end row -->
  </Layout>
</template>
